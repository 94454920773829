import React, { useEffect, useState } from 'react';
import './ResetPassword.css';
import Userfront, { PasswordResetForm } from "@userfront/toolkit/react";
import { useLocation } from 'react-router-dom';

function ResetPassword() {
  const [isFirstTimeReset, setIsFirstTimeReset] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Check if URL contains "uuid" and "token" to identify a first-time reset
    const params = new URLSearchParams(location.search);
    const hasResetParams = params.has('uuid') && params.has('token');
    setIsFirstTimeReset(hasResetParams);
  }, [location.search]);

  return (
    <div className='container'>
      <div className="row">
        <div className="Login">
          <img className='logo' src="/logo.png" alt="LeadgenFlow Logo" />
          <div className={`userfront-form`}>
            <PasswordResetForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;